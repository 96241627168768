<template>
  <div id="app">
    <div id="nav0">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <!-- <navbar/> -->
    <router-view id="detail"/>
    </div>
  </div>
</template>

<script>
// import Navbar from './components/Navbar.vue'

// import footer from './components/Footer.vue'

export default {
  components:{
    // Navbar,
    
    // footer
  },
  data(){
    return{

    }
  },
  mounted(){

  }
}
</script>
<style>
@import "./assets/css/styles.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#detail {
  padding: 0px;
  
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.pointer{
  cursor:pointer
}
</style>
