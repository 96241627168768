import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id',
    name: 'public',
    component: () => import(/* webpackChunkName: "about" */ '../views/Public.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')

    //component: Home
  },
  {
    path: '/users',
    name: 'users',
      component: () => import('../components/users.vue')
  },
]

const router = new VueRouter({
  //mode: "history",
  // base: process.env.BASE_URL,  
  routes
})

export default router
