<template>

<div class="app">

  <div class="container"><br>
      <div class="row justify-content-center">
          <div class="col-md-4 text-center">
              <button @click="goto('users')" class="btn btn-danger btn-block" style="width: 100%; height: 120px;">
                  <i class="fa fa-users"></i> <br> EMPLEADOS
              </button>
          </div>
          <div class="col-md-4 text-center">
              <button @click="gotoConfiguration" class="btn btn-dark btn-block" style="width: 100%; height: 120px;">
                  <i class="fa fa-users"></i> <br> CONFIGURACION
              </button>
          </div>
          <div class="col-md-4 text-center">
              <button @click.prevent="logout" class="btn btn-secondary  btn-block" style="width: 100%; height: 120px;">
                  <i class="fa fa-sign-out"></i> <br> SALIR 
              </button>
          </div>
      </div>
  </div>

  <div class="container mt-2 mb-5"><br>
    <div class="container mt-1"><br>
      <h3 class="text-center">PROXIMOS 5 CUMPLEAÑEROS:</h3>
      <div class="row justify-content-center mt-5">
        <div class="col-md-2" v-for="item of lastFive" :key="item.id" >
          <img :src="item.picture!= 'null' && item.picture!= null ? item.picture : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCpawWq8L8kk4tzxZ6h-svcCsRbQFvUk2KfYnaBg7MOcqvhYdIJUL60i5pBI-f_whkQ90&usqp=CAU'" class="img-responsive rounded-pill borde" alt="...">
          <div class="card-body">
              <h5 style="height:50px; font-weight:bold" class="card-title">{{ item.name }}</h5>
              <span class="card-text">{{ item.birthday.substr(0,10) }}</span>
              <!-- <p class="card-text"><span style='text-decoration: underline'>&nbsp;{{ item.age + ' años' }} &nbsp;</span></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal id="editConfig-modal"  hide-footer title="EDITAR CONFIGURACION">
    <input type="text" class="form-control" placeholder="ingresa url video" />
    <div class="row justify-content-center"> 
        <div class="offset-md-4 col-md-4"> 
            <b-button @click="$bvModal.hide('editUser-modal')" class="btn btn-secondary btn-block mt-5" type="button" style="height: 70px; width:100px" >CANCELAR &nbsp;</b-button>
        </div>
        <div class="col-md-4"> 
            <b-button @click="$bvModal.hide('editUser-modal')" class="btn btn-secondary btn-block mt-5" type="button" style="height: 70px; width:100px" >CANCELAR &nbsp;</b-button>
        </div>
    </div>
  </b-modal>   

</div>
      

</template>

<script>
// @ is an alias to /src

export default {
  // props:[ "list" ],
  name: 'Home',
  components: {
    // HelloWorld
  }, data(){
    return{
      sliders:[],
      slide: 0,
      sliding: null,
      storeImages:[],
      paymentMethods: [],
      
      lastFive: []
    }
  },
  async mounted(){
    // await this.getSliders()
    // this.getStoreImages();
    // this.getPaymentMethods();
    await this.getLastFive();

  },
  methods:{
    logout(){
      localStorage.setItem('logged', false)
      this.$store.commit('updateUserRefresh');
      this.$router.push('/')
    },
    async gotoConfiguration(){
      let text 
      let data = prompt("ingresa la url del video:")
      if(data == null || data == ''){
        //cancelado
      }
      else{
        //guardar info
        let path = 'config/1'
        let msg = 'Datos actualizados correctamente.'
        let options = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify({url: data})
        }
        let req = await fetch(this.$store.state.url + path, options)
        if (req.ok) {
          alert(msg)
          //this.$store.commit('updateUserList', true);
        }
      }
    },
    async getLastFive(){
        let req = await fetch(this.$store.state.url + "getLastFive")
        let lastFive = await req.json()
        // for await (let item of lastFive){
        //     item.age = this.getAge(item.birthday)
        // }
        this.lastFive = lastFive
    },
    goto(){
      this.$router.push('users')
    }
  }
}
</script>
<style scoped>
  
.app{
  margin-top: 150px !important;
}
.modal-dialog {
    max-width: auto;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100vh; */
    display: flex;
}
</style>